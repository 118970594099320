@import '/src/assets/styles/variables.scss';

.loader-space{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 200px;
        height: 200px;
    }
    .lds-ripple div {
        position: absolute;
        border: 6px solid $primary-green-faint2;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
}
.half-space{
    height: 60vh;
}
@keyframes lds-ripple {
    0% {
        top: 90px;
        left: 90px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 90px;
        left: 90px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 90px;
        left: 90px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 180px;
        height: 180px;
        opacity: 0;
    }
}
  